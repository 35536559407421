<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    <grid
      item-min-width="300px"
      class="align-center"
      v-if="hasErrors"
    >
      <v-text-field
        v-for="(error, idx) in errors"
        :key="idx"
        class="mx-2"
        :value="error"
        hide-details
        readonly
      />
    </grid>
    <template v-else>
      <grid
        item-min-width="300px"
        class="align-center"
      >
        <v-text-field
          class="mx-2 mb-2"
          :label="$t('t.ActionType')"
          :value="action"
          hide-details
          readonly
        />
        <v-text-field
          class="mx-2 mb-2"
          :label="$t('t.LineCount')"
          :value="nbrLines"
          hide-details
          readonly
        />
      </grid>
      <grid
        item-min-width="300px"
        class="align-center"
      >
        <template v-for="value in values">
          <div
            class="mx-2 mb-2"
            :key="value.label"
          >
            <div
              v-if='value.component.showLabel'
              class="custom-label"
            >
              {{value.label}}
            </div>
            <component
              :is="value.component.componentName"
              :item='value.item'
              :label="value.label"
              :value="value.item"
              v-model="value.item"
              :ripple="false"
              hide-details
              readonly
            ></component>
          </div>
        </template>
      </grid>
    </template>
  </div>
</template>

<script>

import { VCheckbox } from 'vuetify/lib'

export default {
  name: 'bulk-action',
  components: {
    Grid: () => import('@/components/grid'),
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    VCheckbox
  },
  computed: {
    hasErrors () {
      return this.errors.length
    },
    labels () {
      return {
        businessDivisionId: this.$t('t.BusinessDivision'),
        closed: this.$t('t.Complete'),
        collectionSegmentId: this.$t('t.CollectionSegment'),
        clearHistory: this.$t('t.ClearHistory'),
        customField1: this.$t('t.CustomField1'),
        customField2: this.$t('t.CustomField2'),
        customField3: this.$t('t.CustomField3'),
        customField4: this.$t('t.CustomField4'),
        customField5: this.$t('t.CustomField5'),
        customField6: this.$t('t.CustomField6'),
        customField7: this.$t('t.CustomField7'),
        customField8: this.$t('t.CustomField8'),
        customField9: this.$t('t.CustomField9'),
        customField10: this.$t('t.CustomField10'),
        customField11: this.$t('t.CustomField11'),
        customField12: this.$t('t.CustomField12'),
        customField13: this.$t('t.CustomField13'),
        customField14: this.$t('t.CustomField14'),
        customField15: this.$t('t.CustomField15'),
        customField16: this.$t('t.CustomField16'),
        customField17: this.$t('t.CustomField17'),
        customField18: this.$t('t.CustomField18'),
        customField19: this.$t('t.CustomField19'),
        customField20: this.$t('t.CustomField20'),
        customField21: this.$t('t.CustomField21'),
        customField22: this.$t('t.CustomField22'),
        customField23: this.$t('t.CustomField23'),
        customField24: this.$t('t.CustomField24'),
        customField25: this.$t('t.CustomField25'),
        customField26: this.$t('t.CustomField26'),
        customField27: this.$t('t.CustomField27'),
        customField28: this.$t('t.CustomField28'),
        customField29: this.$t('t.CustomField29'),
        customField30: this.$t('t.CustomField30'),
        comment: this.$t('t.Comment'),
        dunningBlocked: this.$t('t.DunningDisabled'),
        excludeFromDso: this.$t('t.ExcludeFromDSO'),
        excludeFromDunning: this.$t('t.DunningDisabled'),
        isActive: this.$t('t.Active'),
        isAllowPortalAccess: this.$t('t.AllowPortalAccess'),
        isBlockingProtocol: this.$t('t.BlockingProtocol'),
        isClosed: this.$t('t.Closed'),
        mainSalesRepId: this.$t('t.MainSalesRep'),
        parentId: this.$t('t.ParentItem'),
        priority: this.$t('t.WorkItemPriorities'),
        riskMonitored: this.$t('t.RiskMonitored'),
        secondSalesRepId: this.$t('t.SecondSalesRep'),
        targetDate: this.$t('t.TargetDate'),
        transactionTypeId: this.$t('t.TransactionType'),
        updateAction: this.$t('t.ProcessAction'),
        userId: this.$t('t.User'),
        workQueueId: this.$t('t.WorkQueue')
      }
    }
  },
  data () {
    return {
      values: [],
      errors: [],
      nbrLines: 0,
      action: ''
    }
  },
  methods: {
    getComponent (value) {
      if (typeof value === 'object') {
        return { componentName: 'document-picker-list-item-ref', showLabel: true }
      } else if (!isNaN(Date.parse(value))) {
        return { componentName: 'date-picker-menu' }
      } else if (this.lodash.isBoolean(value)) {
        return { componentName: 'v-checkbox' }
      } else {
        return { componentName: 'v-text-field' }
      }
    },
    translateItem (label, item) {
      switch (label) {
        case 'updateAction':
          switch (item) {
            case 'close': return this.$t('t.Complete')
            case 'reassign': return this.$t('t.Reassign')
            case 'report': return this.$t('t.Report')
          }
      }
      return item
    }
  },
  async created () {
    const { data } = await this.$http().get(`/core/run/recap/${this.$route.params.id}`)
    const { title, content } = data
    this.$store.commit('setModule', {
      name: title ?? this.$t('t.BulkAction')
    })
    this.action = content.action
    this.errors = content.errors
    this.nbrLines = content.nbrLines
    if (content.detail) {
      this.values = Object.entries(content.detail).filter(([key, item]) => item !== null)
        .map(([key, item]) => ({
          label: this.labels[key],
          component: this.getComponent(item),
          item: this.translateItem(key, item)
        }))
    }
  }
}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.v-text-field
  min-width 250px

.custom-label
  height 20px
  font-size 12px
  top 6px
</style>
